<template>
  <el-scrollbar class="avue-menu">
    <div v-if="userStore().router&&userStore().router.length===0&&!layoutStore().isHorizontal"
         class="avue-sidebar--tip">没有发现菜单
    </div>
    <el-menu :collapse="getScreen(layoutStore().isCollapse)"
             :default-active="$route.path"
             router
             text-color="#fff"
             unique-opened>
      <sidebar-item
          v-for="menu in  userStore().router"
          :key="menu.id"
          :item="menu"
      />
    </el-menu>
  </el-scrollbar>


  <el-dialog
      v-model="googleSecretState"
      title="为了您的财产安全，请使用谷歌验证器"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"

  >

    <h3>1.下载谷歌验证器</h3>
    <p>苹果用户：App Store 搜索 谷歌验证 下载</p>
    <p>安卓用户：自带应用商店 搜索 谷歌验证 下载</p>

    <h3>2.打开身份验证器扫码绑定账号，有两种方式</h3>
    <p>第一种（推荐）：打开谷歌验证器，扫描下发二维码绑定</p>
    <qrc-code-vue :value="qrcCode" :size="200"/>
    <p>第二种：打开谷歌验证器，输入下方密钥绑定</p>
    <p>密钥： <span style="color: red">{{ googleSecret }}</span>  <el-button type="primary" v-on:click="copyGoSecret">复制</el-button></p>

    <h3>3.确认绑定成功</h3>
    <p>第2步绑定成功后，谷歌验证app上会有30秒更换一次的验证码，请将验证码输入下方输入框，确定开启谷歌验证。</p>
    <p>注意：开启验证后，以后登录都必须输入谷歌验证码才能登录</p>

    <el-input v-model="inputCode" placeholder="请输入谷歌验证码" style="width: 200px;"></el-input>
    <el-button type="primary" v-on:click="bindGoogleCode">确定</el-button>

  </el-dialog>

</template>


<script setup>
import SidebarItem from "./sidebarItem.vue";
import layoutStore from "@/store/modules/layout";
import userStore from "@/store/modules/user";
import {getScreen} from '@/utils/util'
import {ref} from "vue";
import request from "@/request";
import {ElMessage} from "element-plus";
import QrcCodeVue from "qrcode.vue"


const qrcCode = ref("");


const inputCode = ref("");

let storeUser = userStore();
const googleSecretState = ref(storeUser.info.googleSecretState === 0);
const googleSecret = ref("");
// 判断是否已绑定谷歌验证器
if (googleSecretState.value) {
  // 更新验证器密钥
  request.get('/update/secret/key').then(res => {
    if (res.code === 0) {
      googleSecret.value = res.data;
      qrcCode.value = "otpauth://totp/" + storeUser.info.title + "?secret=" + googleSecret.value;

    } else {
      ElMessage.error(res.msg);
    }
  })
}


// 根据输入绑定谷歌验证码
const bindGoogleCode = () => {
  request.get('/bind/google/code', {googleSecret: googleSecret.value, code: inputCode.value}).then(res => {
    if (res.code === 0) {
      ElMessage.success("绑定成功");
      userStore().logout()
    } else {
      ElMessage.error(res.msg);
    }
  })
}

const copyGoSecret = () => {
  const textarea = document.createElement('textarea');
  textarea.value = googleSecret.value;

  // 将 textarea 元素添加到文档中
  document.body.appendChild(textarea);

  // 选中 textarea 中的文本
  textarea.select();

  try {
    // 尝试将选中的文本复制到剪贴板
    document.execCommand('copy');
    console.log('文本已成功复制到剪贴板');
  } catch (error) {
    console.error('复制文本到剪贴板失败:', error);
  } finally {
    // 清理工作：移除临时的 textarea 元素
    document.body.removeChild(textarea);
  }
}


</script>
