import {defineStore} from "pinia";
import storage from "@/utils/storage";
import router from "@/router";
import dynamic from "@/router/openTreeToArray";
import tabsStore from "@/store/modules/tabs";


/**
 * @description: 用于用户数据
 */
const userStore = defineStore("user", {
    state: () => ({
        /**
         * token
         */
        token: storage.get("token"),
        /**
         * 用户信息
         */
        info: {},
        /**
         * 初始化路由标记
         */
        initRouter: false,
        /**
         * 路由
         */
        router: []
    }),
    actions: {
        /**
         * 是否有推广权限
         */
        isAgent() {
            return this.info.level === 1 || this.info.level === 2;
        },
        /**
         * @description: 保存token
         * @param token
         */
        saveToken(token) {
            storage.set("token", token);
            this.token = token;
        },
        /**
         * @description: 保存用户信息 角色以及权限数据
         * @param data
         */
        saveUserInfoRolesPermissions(data) {
            this.info = data.user;
        },
        /**
         * @description: 设置路由
         */
        setRouter() {

            this.router = [
                {
                    "id": "1",
                    "title": "首页",
                    "path": "/index",
                    "component": "Index",
                    "query": "",
                    "type": "menu",
                    "icon": "el-icon-house",
                    "visible": 1,
                },
                // 推广管理
                {
                    "id": "2",
                    "title": "下级管理",
                    "path": "/agent",
                    "component": "Agent",
                    "query": "",
                    "type": "menu",
                    "icon": "el-icon-share",
                    "visible": 1,//this.isAgent() ? 1 : 0,
                },
                // 转账管理
                {
                    "id": "3",
                    "title": "财务管理",
                    "path": "",
                    "query": "",
                    "type": "dir",
                    "icon": "el-icon-document-remove",
                    "visible": 1,
                    children: [
                        {
                            "id": "31",
                            "title": "充值记录",
                            "path": "/recharge",
                            "component": "Recharge",
                            "query": "",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "32",
                            "title": "提现管理",
                            "path": "/withdrawal",
                            "component": "Withdrawal",
                            "query": "",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "33",
                            "title": "转账管理",
                            "path": "/transfer",
                            "component": "Transfer",
                            "query": "",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                    ]
                },

                {
                    "id": "40",
                    "title": "收款码管理",
                    "path": "/qrc",
                    "type": "dir",
                    "icon": "el-icon-picture",
                    "visible": 1,
                    children: [
                        {
                            "id": "401",
                            "title": "TB代付",
                            "path": "/qrc/QrcAlipayXianyu",
                            "component": "qrc/QrcAlipayXianyu",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "426",
                            "title": "TB代付-店铺",
                            "path": "/device/AlipayXianyu",
                            "component": "device/AlipayXianyu",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "427",
                            "title": "TB代付-店铺(CK)",
                            "path": "/device/AlipayDf",
                            "component": "device/AlipayDf",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "402",
                            "title": "支付宝金条",
                            "path": "/qrc/QrcAlipayUid",
                            "component": "qrc/QrcAlipayUid",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        // {
                        //     "id": "403",
                        //     "title": "支付宝AA",
                        //     "path": "/qrc/QrcAlipayAA",
                        //     "component": "qrc/QrcAlipayAA",
                        //     "type": "menu",
                        //     "icon": "el-icon-menu",
                        //     "visible": 1,
                        // },
                        {
                            "id": "404",
                            "title": "支付宝AAck",
                            "path": "/qrc/QrcAlipayAACK",
                            "component": "qrc/QrcAlipayAACK",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        // {
                        //     "id": "404",
                        //     "title": "微信扫码",
                        //     "path": "/qrc/QrcWXScan",
                        //     "component": "qrc/QrcWXScan",
                        //     "type": "menu",
                        //     "icon": "el-icon-menu",
                        //     "visible": 1,
                        // },
                        {
                            "id": "405",
                            "title": "闲鱼直付",
                            "path": "/qrc/QrcXianYuZf",
                            "component": "qrc/QrcXianYuZf",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "406",
                            "title": "支付宝收款单",
                            "path": "/qrc/QrcAlipayYinLian",
                            "component": "qrc/QrcAlipayYinLian",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        // {
                        //     "id": "407",
                        //     "title": "支付宝放心充",
                        //     "path": "/qrc/QrcAlipayFxc",
                        //     "component": "qrc/QrcAlipayFxc",
                        //     "type": "menu",
                        //     "icon": "el-icon-menu",
                        //     "visible": 1,
                        // },
                        {
                            "id": "408",
                            "title": "支付宝手动回调",
                            "path": "/qrc/QrcManualAlipayUid",
                            "component": "qrc/QrcManualAlipayUid",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "409",
                            "title": "淘宝直付",
                            "path": "/qrc/QrcTaoBaoZf",
                            "component": "qrc/QrcTaoBaoZf",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "410",
                            "title": "支付宝小荷包",
                            "path": "/qrc/QrcAlipayXhb",
                            "component": "qrc/QrcAlipayXhb",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "411",
                            "title": "支付宝LS",
                            "path": "/qrc/QrcAlipayLs",
                            "component": "qrc/QrcAlipayLs",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "412",
                            "title": "淘宝游戏",
                            "path": "/qrc/QrcTaoBaoGame",
                            "component": "qrc/QrcTaoBaoGame",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "413",
                            "title": "抖音",
                            "path": "/qrc/QrcTK",
                            "component": "qrc/QrcTK",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "414",
                            "title": "支付宝当面付",
                            "path": "/qrc/QrcAlipayDMF",
                            "component": "qrc/QrcAlipayDMF",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "415",
                            "title": "支付宝当面付-分账",
                            "path": "/qrc/QrcAlipayDmfFenZhang",
                            "component": "qrc/QrcAlipayDmfFenZhang",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "416",
                            "title": "支付宝手机网站",
                            "path": "/qrc/QrcAlipayWap",
                            "component": "qrc/QrcAlipayWap",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "417",
                            "title": "手机网站分账",
                            "path": "/qrc/QrcAlipayWapFenZhang",
                            "component": "qrc/QrcAlipayWapFenZhang",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "418",
                            "title": "支付宝订单码",
                            "path": "/qrc/QrcAlipayOrderPay",
                            "component": "qrc/QrcAlipayOrderPay",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "432",
                            "title": "支付宝App",
                            "path": "/qrc/QrcAlipayApp",
                            "component": "qrc/QrcAlipayApp",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "419",
                            "title": "完美世界",
                            "path": "/qrc/QrcWanmei",
                            "component": "qrc/QrcWanmei",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "420",
                            "title": "TL",
                            "path": "/qrc/QrcTl",
                            "component": "qrc/QrcTl",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "421",
                            "title": "抖音微播",
                            "path": "/qrc/QrcDouyin",
                            "component": "qrc/QrcDouyin",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "422",
                            "title": "售货机",
                            "path": "/qrc/QrcShj",
                            "component": "qrc/QrcShj",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 0,
                        },{
                            "id": "423",
                            "title": "礼品卡",
                            "path": "/qrc/QrcLPK",
                            "component": "qrc/QrcLPK",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "424",
                            "title": "支付宝现金红包",
                            "path": "/qrc/QrcAlipayRedpacket",
                            "component": "qrc/QrcAlipayRedpacket",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "425",
                            "title": "抖音转账",
                            "path": "/qrc/QrcDouYinZhuanzhang",
                            "component": "qrc/QrcDouYinZhuanzhang",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        /*{
                            "id": "403",
                            "title": "支付宝H5",
                            "path": "/qrc/QrcAlipayH5",
                            "component": "qrc/QrcAlipayH5",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                            "query": ""
                        }*/
                        {
                            "id": "428",
                            "title": "交易猫",
                            "path": "/qrc/QrcJYM",
                            "component": "qrc/QrcJYM",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "429",
                            "title": "云创",
                            "path": "/qrc/QrcYunChuang",
                            "component": "qrc/QrcYunChuang",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "430",
                            "title": "中吉",
                            "path": "/qrc/QrcZhongji",
                            "component": "qrc/QrcZhongji",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "431",
                            "title": "京东",
                            "path": "/qrc/QrcJingdongDF",
                            "component": "qrc/QrcJingdongDF",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "432",
                            "title": "直付通第三方",
                            "path": "/qrc/QrcZFT3rd",
                            "component": "qrc/QrcZFT3rd",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "433",
                            "title": "盛马售货机",
                            "path": "/device/SMShj",
                            "component": "device/SMShj",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                        {
                            "id": "434",
                            "title": "代充",
                            "path": "/qrc/QrcBalanceDf",
                            "component": "qrc/QrcBalanceDf",
                            "type": "menu",
                            "icon": "el-icon-menu",
                            "visible": 1,
                        },
                    ]
                },


                // 挂码管理
                /*{
                    "title": "挂码管理",
                    "path": "/device",
                    "component": "Device",
                    "query": "",
                    "type": "menu",
                    "icon": "el-icon-iphone",
                    "visible": 1,
                },*/
                // 收款码管理
                /*{
                    "id": "5",
                    "title": "二维码管理",
                    "path": "/qrc",
                    "component": "Qrc",
                    "query": "",
                    "type": "menu",
                    "icon": "el-icon-iphone",
                    "visible": 1,
                },*/
                // 订单查询
                {
                    "id": "6",
                    "title": "订单查询",
                    "path": "/order",
                    "component": "Order",
                    "query": "",
                    "type": "menu",
                    "icon": "el-icon-connection",
                    "visible": 1,
                },
                // 收益统计
                {
                    "id": "7",
                    "title": "设备收益",
                    "path": "/device/income",
                    "component": "DeviceIncome",
                    "query": "",
                    "type": "menu",
                    "icon": "el-icon-pie-chart",
                    "visible": 0,
                },
                // 库存
                {
                    "id": "8",
                    "title": "库存查询",
                    "path": "/qrc/stock",
                    "component": "QrcStock",
                    "query": "",
                    "type": "menu",
                    "icon": "el-icon-coin",
                    "visible": 1,
                },
                // 登录日志
                {
                    "id": "9",
                    "title": "登录日志",
                    "path": "/log/Login",
                    "component": "LogLogin",
                    "query": "",
                    "type": "menu",
                    "icon": "el-icon-folder-opened",
                    "visible": 1,
                },
                {
                    "id": "10",
                    "title": "发货凭证",
                    "path": "/certificate",
                    "component": "Certificate",
                    "query": "",
                    "type": "menu",
                    "icon": "el-icon-ticket",
                    "visible": 1,
                },
                {
                    "id": "10",
                    "title": "上码文档",
                    "path": "/document",
                    "component": "Documents",
                    "query": "",
                    "type": "menu",
                    "icon": "el-icon-document",
                    "visible": 1,
                },
            ];


            // 动态配置路由
            dynamic.appendDynamicRoutes(this.router);
        },
        /**
         * @description: 退出登录
         */
        logout() {
            storage.clearAll();
            this.$reset();
            tabsStore().$reset();
            router.push({path: "/login", replace: true})
        },
    }

})


export default userStore
