import {createRouter, createWebHashHistory} from 'vue-router'
import Layout from '@/layout'
import userStore from "@/store/modules/user";
import request from "@/request";
import pinia from "@/store/index";


const storeUser = userStore(pinia);

/**
 * 路由白名单
 * @type {string[]}
 */
const whiteList = ['/login', '/404', '/403', '/500','/register']


// 公共路由
export const constantRoutes = [// api动态菜单添加到/index下的children中
    {
        path: '',
        component: Layout,
        redirect: '/index',
        meta: {title: '首页', icon: 'dashboard'},
        children: [
            {
                path: '/index',
                component: () => import('@/pages/Index'),
                name: '首页',
            }
        ]
    },
    {
        path: '/login',
        component: () => import('@/pages/Login'),
        hidden: true
    },
    {
        path: '/register',
        component: () => import('@/pages/Register'),
        hidden: true
    }
]

const router = createRouter({
    history: createWebHashHistory(), routes: constantRoutes, scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {top: 0}
        }
    },
});


/**
 * 路由导航守卫
 */
router.beforeEach(async (to, from, next) => {
    if (to.path === '/login'){
        next()
        return;
    }
    let token = storeUser.token;
    if (token) {
        // 第一次访问 加载router信息
        if (storeUser.initRouter === false) {
            let userInfoRes = await request.get("info");
            storeUser.saveUserInfoRolesPermissions(userInfoRes);
            storeUser.setRouter();
            storeUser.initRouter = true;
            //重新进入to界面,replace: true表示浏览器不需要记录本次历史
            next({...to, replace: true})
        } else {
            next();
        }
    } else {
        if (whiteList.includes(to.path)) {
            next()
        } else {
            next('/login')
        }
    }
});

export default router;
